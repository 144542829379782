<template>
  <div id="school-add-edit-tab-services">
    <h4>{{ data.name }}</h4>
    <div class="vx-row">
      <div v-if="!hasAcademicPeriod" class="vx-col w-full">
        <vx-card class="my-4" content-color="danger">
          <div class="flex">
            <feather-icon icon="AlertCircleIcon" class="mr-2" svgClasses="w-5 h-5"/>
            <p>
             Esta escuela <strong>no</strong> se encuentra adherida a Garantía de Pago. Para adherir esta escuela al producto <strong>Garantía de Pago</strong>, complete el siguiente formulario.
            </p>
          </div>
        </vx-card>
      </div>
      <div v-else class="vx-col w-full">
        <vx-card class="my-4" content-color="success">
          <div class="flex">
            <feather-icon icon="CheckIcon" class="mr-2" svgClasses="w-5 h-5"/>
            <p>
             Esta escuela se encuentra adherida a Garantía de Pago desde el {{ new Date(academic_periods.created_at) }}
            </p>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
          <vx-card>
            <!-- Col Header -->
            <div class="flex items-end">
              <!-- <feather-icon icon="BillIcon" class="mr-2" svgClasses="w-5 h-5" /> -->
              <span class="leading-none font-medium">Ciclo Lectivo {{ dataSchoolYearInitialYear + (dataSchoolYearEndYear != dataSchoolYearInitialYear ? ' - ' + dataSchoolYearEndYear : '' ) }}</span>
            </div>

            <!-- Col Content -->
            <!-- Mes y año de inicio anio escolar -->
            <div class="mt-4 flex flex-wrap">
              <div class="w-1/2">
                <label class="text-sm">Mes y Año de Inicio</label>
                <div class="flex flex-wrap">
                  <div class="w-1/2">
                    <v-select v-model="dataSchoolYearInitialMonth" :clearable="false" :reduce="dataSchoolYearInitialMonth => dataSchoolYearInitialMonth.value" :options="monthOptions" v-validate="'required'" :disabled="hasAcademicPeriod" name="initial_month" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                  </div>
                  <div class="w-1/2 pl-2">
                    <v-select v-model="dataSchoolYearInitialYear" :clearable="false" :reduce="dataSchoolYearInitialYear => dataSchoolYearInitialYear.value" :options="yearOptions" v-validate="'required'" name="initial_year" :disabled="hasAcademicPeriod" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                  </div>
                  <span class="text-danger text-sm"  v-show="errors.has('initial_month')">{{ errors.first('initial_month') }}</span>
                </div>
              </div>
              <div class="pl-4 w-1/2">
                <label class="text-sm">Mes y Año de Fin</label>
                <div class="flex flex-wrap">
                  <div class="w-1/2">
                    <v-select v-model="dataSchoolYearEndMonth" :clearable="false" :reduce="dataSchoolYearEndMonth => dataSchoolYearEndMonth.value" :options="monthOptions" v-validate="'required'" name="end_month" :disabled="hasAcademicPeriod" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                  </div>
                  <div class="pl-2 w-1/2">
                    <v-select v-model="dataSchoolYearEndYear" :clearable="false" :reduce="dataSchoolYearEndYear => dataSchoolYearEndYear.value" :options="yearOptions" v-validate="'required'" name="end_year" :disabled="hasAcademicPeriod" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                  </div>
                  <span class="text-danger text-sm" v-show="errors.has('end_month')">{{ errors.first('end_month') }}</span>
                  <span class="text-danger text-sm" v-show="dataSchoolYearTotalMonths <= 0">Fecha de Fin no puede ser menor a Fecha de Inicio</span>
                </div>
              </div>
            </div>

            <div class="mt-4 flex flex-wrap">
              <div class="w-1/2">
                <!-- Cantidad de mensualidades en ciclo lectivo -->
                <vs-input class="w-full" disabled label="Cantidad de Mensualidades en el Ciclo Lectivo" v-model="dataSchoolYearTotalMonths" v-validate="'required|numeric|min_value:1'" data-vv-as="Cantidad de Mensualidades" name="school_year_total_months" />
                <span class="text-danger text-sm"  v-show="errors.has('school_year_total_months')">{{ errors.first('school_year_total_months') }}</span>
              </div>
              <div class="pl-4 w-1/2">
                <div class="flex flex-wrap">
                  <div class="w-1/2">
                    <div class="flex flex-start">
                        <vs-input class="w-full" type="number" label="Día Venc. Cuota" v-model="expirationDay" v-validate="'required|numeric|min_value:1|max_value:27|max:2'" data-vv-as="Día Venc. Cuota" name="expiration_day" :disabled="paymentReceived"/>
                        <vx-tooltip color="primary" class="inline-form negative-left-margin" text="Este campo representa el día calendario del mes en que se vencerán las cuotas. Ejemplo: si ingresa el valor 5, las cuotas generadas vencerán los días 5 de cada mes, sin importar si se trata de un día hábil o no." position="top">
                            <feather-icon svgClasses="h-5 w-5" class="bg-primary inline-flex rounded-full text-white" icon="HelpCircleIcon"></feather-icon>
                        </vx-tooltip>
                    </div>
                    <span class="text-danger text-sm"  v-show="errors.has('expiration_day')">{{ errors.first('expiration_day') }}</span>
                  </div>
                  <div class="w-1/2">
                    <div class="flex flex-start">
                      <vs-input class="w-full pl-2" type="number" label="Día Pronto Pago" v-model="dataEarlyPaymentDay" v-validate="'required|numeric|min_value:0|max_value:27|max:2|earylyPaymentLessThanExpireDay:true'" data-vv-as="Día Pronto Pago" name="early_payment_day" :disabled="paymentReceived"/>
                      <vx-tooltip color="primary" class="inline-form negative-left-margin" text="Este campo representa el día calendario del mes hasta donde se aplica el Descuento por Pronto Pago. Ejemplo: si ingresa el valor 3, quienes realicen los pagos de mensualidad hasta el día 3 de cada mes inclusive se les aplicará el % de Descuento por Pronto Pago." position="top">
                          <feather-icon svgClasses="h-5 w-5" class="bg-primary inline-flex rounded-full text-white" icon="HelpCircleIcon"></feather-icon>
                        </vx-tooltip>
                    </div>
                    <span class="text-danger text-sm"  v-show="errors.has('early_payment_day')">{{ errors.first('early_payment_day') }}</span>
                  </div>
                </div>

              </div>
            </div>

            <!-- Col Header -->
            <div class="flex items-end md:mt-4 mt-base">
              <!-- <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" /> -->
              <span class="leading-none font-medium">Comisiones Escuela</span>
            </div>

            <div class="mt-4 flex flex-wrap">
              <div class="w-1/2">
                <!-- Penalidad por Adelanto de Dinero -->
                <label class="vs-input--label">% Comisión por Adelanto de Dinero</label>
                <div class="flex flex-wrap">
                  <vs-input-number label="%" v-model.lazy="dataFinancialCommission" :step="0.125" name="financial_commission" class="w-100" @input="checkLength($event, 'dataFinancialCommission')" v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"/>
                  <span class="text-danger text-sm"  v-show="errors.has('financial_commission')">{{ errors.first('financial_commission') }}</span>
                </div>
              </div>
              <!-- % Comisión por Procesamiento de Cobro/Pago -->
              <div class="w-1/2">
                <label class="vs-input--label">% Comisión por Procesamiento de Cobro</label>
                <div class="flex flex-wrap">
                  <vs-input-number label="%" v-model="dataPaymentProcessingFeeSchool" :step="0.125" name="payment_processing_fee_school" class="w-100" @input="checkLength($event, 'dataPaymentProcessingFeeSchool')" v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"/>
                  <span class="text-danger text-sm"  v-show="errors.has('payment_processing_fee_school')">{{ errors.first('payment_processing_fee_school') }}</span>
                </div>
              </div>
            </div>

          </vx-card>

        </div>
        <div class="vx-col w-full md:w-1/2">
          <vx-card>
            <!-- Descuentos Column -->
            <div class="vx-col w-full md:w-1/2">
              <!-- Col Header -->
                <div class="flex items-end md:mt-0 mt-base">
                  <!-- <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" /> -->
                  <span class="leading-none font-medium">Cargos y Descuentos Acudiente</span>
                </div>
            </div>

            <div class="mt-4 flex flex-wrap">
                <!-- Penalidad por morosidad -->
                <div class="w-1/2">
                  <label class="vs-input--label">% de Penalidad por Morosidad</label>
                  <div class="flex flex-wrap">
                    <vs-input-number label="%" v-model="dataLatePaymentPenalty" :step="0.125" name="late_payment_penalty" class="w-100" @input="checkLength($event, 'dataLatePaymentPenalty')" v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"/>
                    <span class="text-danger text-sm"  v-show="errors.has('late_payment_penalty')">{{ errors.first('late_payment_penalty') }}</span>
                  </div>
                </div>
                <!-- Penalidad por morosidad -->
                <div class="w-1/2">
                  <label class="vs-input--label">% Comisión por Procesamiento de Pago</label>
                  <div class="flex flex-wrap">
                    <vs-input-number label="%" v-model="dataPaymentProcessingFeeGuardian" :step="0.125" name="payment_processing_fee_guardians" class="w-100" @input="checkLength($event, 'dataPaymentProcessingFeeGuardian')" v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"/>
                    <span class="text-danger text-sm"  v-show="errors.has('payment_processing_fee_guardians')">{{ errors.first('payment_processing_fee_guardians') }}</span>
                  </div>
                </div>
                <!-- Descuento por Pronto Pago -->
                <div class="w-1/2 mt-4">
                  <label class="vs-input--label">% de Descuento por Pronto Pago</label>
                  <div class="flex flex-wrap">
                    <vs-input-number label="%" v-model="dataEarlyPaymentDiscount" :step="0.125" name="early_payment_discount" class="w-100" @input="checkLength($event, 'dataEarlyPaymentDiscount')" v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"/>
                    <span class="text-danger text-sm"  v-show="errors.has('early_payment_discount')">{{ errors.first('early_payment_discount') }}</span>
                  </div>
                </div>
                <div class="w-1/2 mt-4">
                  <!-- Descuento por Pago Trimestral -->
                  <label class="vs-input--label">% de Descuento por Pago Trimestral</label>
                  <div class="flex flex-wrap">
                    <vs-input-number label="%" v-model="dataQuarterlyPaymentDiscount" :step="0.125" name="quarterly_payment_discount" class="w-100" @input="checkLength($event, 'dataQuarterlyPaymentDiscount')" v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"/>
                    <span class="text-danger text-sm"  v-show="errors.has('quarterly_payment_discount')">{{ errors.first('quarterly_payment_discount') }}</span>
                  </div>
                </div>
            </div>

            <div class="mt-4 flex flex-wrap">
                <!-- Descuento por Pago Semestral -->
                <div class="w-1/2">
                  <label class="vs-input--label">% de Descuento por Pago Semestral</label>
                  <div class="flex flex-wrap">
                    <vs-input-number label="%" v-model="dataSemiannualPaymentDiscount" :step="0.125" name="semiannual_payment_discount" class="w-100" @input="checkLength($event, 'dataSemiannualPaymentDiscount')" v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"/>
                    <span class="text-danger text-sm"  v-show="errors.has('semiannual_payment_discount')">{{ errors.first('semiannual_payment_discount') }}</span>
                  </div>
                </div>
                <div class="w-1/2">
                  <!-- Descuento por Pago Anual -->
                  <label class="vs-input--label">% de Descuento por Pago Anual</label>
                  <div class="flex flex-wrap">
                    <vs-input-number label="%" v-model="dataAnnualPaymentDiscount" :step="0.125" name="annual_payment_discount" class="w-100" @input="checkLength($event, 'dataAnnualPaymentDiscount')" v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"/>
                    <span class="text-danger text-sm"  v-show="errors.has('annual_payment_discount')">{{ errors.first('annual_payment_discount') }}</span>
                  </div>
                </div>
            </div>
          </vx-card>
    </div>

    </div>
    <div class="vx-row mt-6">
      <div class="vx-col w-full md:w-1/2">
        <vx-card>

        </vx-card>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm || loading">{{ hasAcademicPeriod ? 'Editar' : 'Aceptar'}}</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data" :disabled="loading">Cancelar</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
// Store Module
import moduleServices from '@/store/services/moduleServices.js'
import { Validator } from 'vee-validate';

export default {
  components: {
    vSelect,
    flatPickr
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      data_local: JSON.parse(JSON.stringify(this.data)),

      langOptions: [
        { label: "English", value: "english" },
        { label: "Spanish", value: "spanish" },
        { label: "French",  value: "french" },
        { label: "Russian", value: "russian" },
        { label: "German",  value: "german" },
        { label: "Arabic",  value: "arabic" },
        { label: "Sanskrit", value: "sanskrit" },
      ],
      monthOptions: [
        { label: "Enero", value: 1 },
        { label: "Febrero", value: 2 },
        { label: "Marzo",  value: 3 },
        { label: "Abril", value: 4 },
        { label: "Mayo",  value: 5 },
        { label: "Junio",  value: 6 },
        { label: "Julio", value: 7 },
        { label: "Agosto", value: 8 },
        { label: "Septiembre", value: 9 },
        { label: "Octubre", value: 10 },
        { label: "Noviembre", value: 11 },
        { label: "Diciembre", value: 12 },
      ],


      yearOptions: [
        { label: "2023", value: 2023 },
        { label: "2024", value: 2024 },
      ],

      // Data
      expirationDay: 0,
      dataLatePaymentPenalty: 0,
      dataEarlyPaymentDiscount: 0,
      dataEarlyPaymentDay: 0,
      dataSchoolYearInitialMonth: new Date().getMonth() + 1,
      dataSchoolYearInitialYear: new Date().getFullYear(),
      dataSchoolYearEndMonth: new Date().getMonth() + 1,
      dataSchoolYearEndYear: new Date().getFullYear() + 1,
      dataFinancialCommission: 0,
      dataPaymentProcessingFeeSchool: 0,
      dataPaymentProcessingFeeGuardian: 0,
      dataQuarterlyPaymentDiscount: 0,
      dataSemiannualPaymentDiscount: 0,
      dataAnnualPaymentDiscount: 0,

      services: [],
      academic_periods: {},
      loading: false,
      hasAcademicPeriod: false,
      academicPeriodAsociation: false,
      paymentReceived: false

    }
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    },
    dataSchoolYearTotalMonths() {
      return (this.dataSchoolYearEndYear - this.dataSchoolYearInitialYear) * 12 + (this.dataSchoolYearEndMonth - this.dataSchoolYearInitialMonth + 1)
    }
  },
  watch: {
    expirationDay() {
      this.$validator.validate()
    }
  },
  methods: {
    save_changes() {
      if(!this.validateForm) return
      if(this.hasAcademicPeriod) return this.submitEditData()

      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.dialog({
            type: 'confirm',
            color: 'primary',
            title: `Está a punto de adherir esta escuela a Garantía de Pago`,
            text: `La información ingresada sobre el ciclo lectivo no podrá ser modificada posteriormente, ya que se utilizará para generar las cuotas correspondientes para todos los alumnos de esta escuela. ¿Está seguro que desea confirmar?`,
            accept: this.submitAddData,
            acceptText: "Confirmar",
            cancelText: "Cancelar"
          })
        }
      })

    },
    reset_data() {
      // this.data_local = Object.assign({}, this.data)
      this.$router.push('/institution/schools')
    },
    fetch_services() {
      // this.$store.dispatch("servicesModule/getServicesByInstitution", this.$route.params.idSchool)
      this.$store.dispatch("servicesModule/getRecurringPaymentServices")
        .then(() => {
          this.services = this.$store.state['servicesModule'].services
        })
        .catch(error => {console.log(error)})
    },
    fetch_school_academic_periods() {
      this.academic_periods = {}
      this.$store.dispatch(`servicesModule/getSchoolAcademicPeriodBySchool`, this.$route.params.idSchool)
        .then(() => {
          this.academic_periods = this.$store.getters["servicesModule/getSchoolAcademicPeriod"]
          if(this.academic_periods && typeof this.academic_periods === 'object' && this.academic_periods !== null && Object.keys(this.academic_periods).length > 0) {
            this.fillAcademicPeriodData()
          }
        })
        .catch(err => console.log(err))
    },
    fillAcademicPeriodData() {
      const beginningDate = new Date(new Date(this.academic_periods.beginning_date).getFullYear(), new Date(this.academic_periods.beginning_date).getMonth(), new Date(this.academic_periods.beginning_date).getDate() + 1)
      const finishingDate = new Date(new Date(this.academic_periods.finishing_date).getFullYear(), new Date(this.academic_periods.finishing_date).getMonth(), new Date(this.academic_periods.finishing_date).getDate() + 1)

      this.hasAcademicPeriod = true
      this.paymentReceived = this.academic_periods.payment_received
      this.expirationDay = this.academic_periods.default_expiration_day || 0
      this.dataEarlyPaymentDay = this.academic_periods.default_early_payment_day || 0
      this.dataSchoolYearInitialMonth = beginningDate.getMonth() + 1 || 3
      this.dataSchoolYearInitialYear = beginningDate.getFullYear() || new Date().getFullYear() + 1
      this.dataSchoolYearEndMonth = finishingDate.getMonth() + 1 || 12
      this.dataSchoolYearEndYear = finishingDate.getFullYear() || new Date().getFullYear() + 1
      this.dataPaymentProcessingFeeGuardian = this.academic_periods.tax && this.academic_periods.tax.length > 0 ? this.academic_periods.tax.find(t => t.code_item == "CSERVICE").value : 0
      this.dataLatePaymentPenalty = this.academic_periods.tax && this.academic_periods.tax.length > 0 ? this.academic_periods.tax.find(t => t.code_item == "CLPPENALTY").value : 0
      this.dataEarlyPaymentDiscount = this.academic_periods.tax && this.academic_periods.tax.length > 0 ? this.academic_periods.tax.find(t => t.code_item == "DEPAYMENT").value : 0
      this.dataQuarterlyPaymentDiscount = this.academic_periods.tax && this.academic_periods.tax.length > 0 ? this.academic_periods.tax.find(t => t.code_item == "DADVANCEDPAY1").value : 0
      this.dataSemiannualPaymentDiscount = this.academic_periods.tax && this.academic_periods.tax.length > 0 ? this.academic_periods.tax.find(t => t.code_item == "DADVANCEDPAY2").value : 0
      this.dataAnnualPaymentDiscount = this.academic_periods.tax && this.academic_periods.tax.length > 0 ? this.academic_periods.tax.find(t => t.code_item == "DADVANCEDPAY3").value : 0
      this.dataPaymentProcessingFeeSchool = this.academic_periods.tax && this.academic_periods.tax.length > 0 ? this.academic_periods.tax.find(t => t.code_item == "CSERVICE2").value : 0
      this.dataFinancialCommission = this.academic_periods.tax && this.academic_periods.tax.length > 0 ? this.academic_periods.tax.find(t => t.code_item == "CADVANCED").value : 0
    },
    getServiceType() {
      return (this.services && Array.isArray(this.services) && this.services.length > 0) ? this.services[0].id_service_type : 0
    },
    showNotify(type,title,text) {
      this.$vs.notify({
        color: type || 'success',
        title: title || 'Correcto!',
        text: text || '',
        time: 8000,
        position: 'top-center',
      })
    },
    submitData(payload, action, successMsg) {
      this.loading = true

      this.$store.dispatch(`servicesModule/${action}`, payload)
        .then(response => {
          if(response.status) {
            this.showNotify('success', 'Éxito!', successMsg)
            this.$router.push('/institution/schools')
          }
          else
            this.showNotify('danger', 'Error', response.msg)
        })
        .finally(() => this.loading = false)
        .catch(err => {
          this.showNotify("danger", "Error", err)
        })
    },
    submitEditData() {
      const obj = {
        id: this.academic_periods.id,
        id_school: parseInt(this.$route.params.idSchool),
        beginning_year: this.dataSchoolYearInitialYear,
        beginning_month: this.dataSchoolYearInitialMonth,
        finishing_year: this.dataSchoolYearEndYear,
        finishing_month: this.dataSchoolYearEndMonth,
        fee_quantity: this.dataSchoolYearTotalMonths,
        default_expiration_day: this.expirationDay,
        default_early_payment_day: this.dataEarlyPaymentDay,
        id_service_types: this.getServiceType(),
        tax: [
          {
            code_item:  "CSERVICE",
            value: this.dataPaymentProcessingFeeGuardian
          },
          {
            code_item:  "CLPPENALTY",
            value: this.dataLatePaymentPenalty
          },
          {
            code_item:  "DEPAYMENT",
            value: this.dataEarlyPaymentDiscount
          },
          {
            code_item:  "DADVANCEDPAY1",
            value: this.dataQuarterlyPaymentDiscount
          },
          {
              code_item:  "DADVANCEDPAY2",
              value: this.dataSemiannualPaymentDiscount
          },
          {
              code_item:  "DADVANCEDPAY3",
              value: this.dataAnnualPaymentDiscount
          },
          {
              code_item:  "CSERVICE2",
              value: this.dataPaymentProcessingFeeSchool
          },
          {
              code_item:  "CADVANCED",
              value: this.dataFinancialCommission
          }
        ]
      }

      this.submitData(obj, "editSchoolAcademicPeriod", "Se han actualizado los datos correctamente.")
    },
    submitAddData() {
      this.loading = true

      const obj = {
        id_school: parseInt(this.$route.params.idSchool),
        beginning_year: this.dataSchoolYearInitialYear,
        beginning_month: this.dataSchoolYearInitialMonth,
        finishing_year: this.dataSchoolYearEndYear,
        finishing_month: this.dataSchoolYearEndMonth,
        fee_quantity: this.dataSchoolYearTotalMonths,
        default_expiration_day: this.expirationDay,
        default_early_payment_day: this.dataEarlyPaymentDay,
        id_service_types: this.getServiceType(),
        tax: [
          {
            code_item:  "CSERVICE",
            value: this.dataPaymentProcessingFeeGuardian
          },
          {
            code_item:  "CLPPENALTY",
            value: this.dataLatePaymentPenalty
          },
          {
            code_item:  "DEPAYMENT",
            value: this.dataEarlyPaymentDiscount
          },
          {
            code_item:  "DADVANCEDPAY1",
            value: this.dataQuarterlyPaymentDiscount
          },
          {
              code_item:  "DADVANCEDPAY2",
              value: this.dataSemiannualPaymentDiscount
          },
          {
              code_item:  "DADVANCEDPAY3",
              value: this.dataAnnualPaymentDiscount
          },
          {
              code_item:  "CSERVICE2",
              value: this.dataPaymentProcessingFeeSchool
          },
          {
              code_item:  "CADVANCED",
              value: this.dataFinancialCommission
          }
        ]
      }

      this.submitData(obj, "addSchoolAcademicPeriod", "La escuela ha sido adherida a Garantía de Pago correctamente.")

    },
    checkLength(value, model) {
      if(value < 0 || value == '')
        value = 0

      value = (typeof value == 'string') ? parseFloat(value).toFixed(3) : value.toFixed(3);

      this[model] = value
    },
  },
  created() {
    // Register Service Module
    if(!moduleServices.isRegistered) {
      this.$store.registerModule('servicesModule', moduleServices)
      moduleServices.isRegistered = true
    }
    this.fetch_services()
    this.fetch_school_academic_periods()

    Validator.extend('earylyPaymentLessThanExpireDay', {
      getMessage: field => 'El campo ' + field + ' debe ser menor o igual al campo Día Venc. Cuota',
      validate: value => !(parseInt(value) > parseInt(this.expirationDay))
    });
    new Validator({ earylyPaymentLessThanExpireDayField: 'earylyPaymentLessThanExpireDay' });
  }
}
</script>
<style scoped>
  .inline-form.negative-left-margin {
    margin-left: -20px !important;
  }
</style>
