<template>
  <div id="page-user-edit">

    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.idSchool }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="entity_data">

      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Garantía de Pago" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <school-services-edit-tab class="mt-4" :data="entity_data" />
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import SchoolServicesEditTab from "./SchoolServicesEditTab.vue"

// Store Module
import moduleDataListInstitutions from '@/store/data-list-institutions/moduleDataList.js'

export default {
  components: {
    SchoolServicesEditTab,
  },
  data() {
    return {
      entity_data: null,
      user_not_found: false,
      activeTab: 0,
    }
  },
  watch: {
    activeTab() {
      this.fetch_entity_data(this.$route.params.idSchool)
    }
  },
  methods: {
    fetch_entity_data(schoolId) {
        this.entity_data = this.$store.state.dataListInstitutions.schools.find(u => u.id == schoolId);
    },
  },
  created() {
    // Register Module dataListInstitutions Module
    if(!moduleDataListInstitutions.isRegistered) {
      this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
      moduleDataListInstitutions.isRegistered = true
    }
    this.fetch_entity_data(this.$route.params.idSchool)
  }
}

</script>
